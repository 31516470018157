





























import { defineComponent, ref, onMounted, useRoute } from '@nuxtjs/composition-api';
import { Logger } from '@vue-storefront/core';
import { LinkType } from '~/types/components/Link/LinkType';
import SimpleCarousel from '~/components/molecules/SimpleCarousel/SimpleCarousel.vue';
import { RELATED_PRODUCTS_CONFIGURATION } from '~/constants/simpleCarousel';
import { useCheckoutTagManager, useIntegrations } from '~/composables';
import { BloomreachProduct } from '~/types/product/BloomreachProduct';
import ProductAnchorTitle from '~/components/atoms/Product/ProductAnchorTitle/ProductAnchorTitle.vue';
import { getLastProductWithBreadcrumbs } from '~/helpers/getLastProductWithBreadcrumbs';
import BloomreachProductCart
  from '~/components/molecules/Product/Bloomreach/BloomreachProductCart/BloomreachProductCart.vue';
import { EVENT_SOURCE } from '~/constants/googleTagManager';

export default defineComponent({
  name: 'BloomreachCartRecommendationProducts',
  components: {
    BloomreachProductCart,
    SimpleCarousel,
    ProductAnchorTitle
  },
  setup() {
    const { $bloomreach, $cia } = useIntegrations();
    const { products, loadProducts } = useCheckoutTagManager();
    const route = useRoute();
    const bloomreachProducts = ref<BloomreachProduct[] | null>(null);
    const recommendationId = $bloomreach.state.bloomreachDetails.similarProductsId ?? '';

    onMounted(async () => {
      await loadProducts();
      bloomreachProducts.value = await $bloomreach.getCartRecommendations(
        getLastProductWithBreadcrumbs(products.value)
      );
      try {
        $cia.event.recommendationsShown(
          recommendationId,
          route.value.params.slug
        );
      } catch (error) {
        Logger.error(`cia|recommendationsShown error: ${error}`);
      }
    });

    return {
      bloomreachProducts,
      LinkType,
      RELATED_PRODUCTS_CONFIGURATION,
      EVENT_SOURCE
    };
  }
});
